const RTL_REGEX = /[\p{Script=Hebrew}\p{Script=Arabic}]/giu;
const LTR_REGEX = /[\p{Script=Latin}]/giu;

// Do we have *any* RTL chars?
export function hasRTL(input: string): boolean {
  return !!countRegexMatch(input, RTL_REGEX);
}

function countRegexMatch(input: string, r: RegExp): number {
  return input ? (input.match(r) || []).length : 0;
}

// Do we have *more* RTL than non RTL chars?
export function isRTL(input: string | undefined): boolean {
  if (!input) {
    return false;
  }
  return countRegexMatch(input, RTL_REGEX) > countRegexMatch(input, LTR_REGEX);
}
